/* eslint-disable no-lone-blocks */
import React, { useState } from "react";

import { GridActionsCellItem } from "@mui/x-data-grid";

import Loader from "../../../components/Loader/Loader";

import Modal from "../../../components/Modal/Modal";

import Table from "../../../components/Table/Table";

import { AiOutlineEdit } from "react-icons/ai";

import ProductDetails from "../ProductDetails";

function AllProducts({ products, change, setChange, loading }) {
  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const columns = [
    { field: "name", headerName: "Product Name", width: 200 },
    { field: "itemNumber", headerName: "Item Number", width: 200 },
    { field: "price", headerName: "Unit Price", width: 200 },
    { field: "availability", headerName: "Availability", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AiOutlineEdit />}
          label="Edit"
          onClick={() => {
            setShow(true);
            setSelectedProduct(params.row);
          }}
        />,
      ],
    },
  ];

  let rows = [];

  if (!loading) {
    products.forEach((product) => {
      rows.push({
        ...product,
        availability: product.isAvailabile ? "Available" : "Out of Stock",
      });
      // rows.push({
      //   itemNumber: product.itemNumber,
      //   id: product.id,
      //   name: capitalize(product.name),
      //   unitPrice: product.price,
      //   availability: product.isAvailabile ? "Available" : "Out of Stock",
      // });
    });
  }

  return (
    <div>
      <Modal title="Edit Product" isOpen={show} setIsOpen={setShow}>
        <ProductDetails
          change={change}
          setChange={setChange}
          setIsOpen={setShow}
          selectedProduct={selectedProduct}
        />
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <Table
            sort="asc"
            sortField="name"
            pagination={100}
            rows={rows}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
}

export default AllProducts;

{
  /* <div className="hidden md:flex">
  <table class="table-auto text-left w-full text-sm font-normal">
    <thead>
      <tr className="border-b">
        <th>#Product No</th>
        <th>Name</th>
        <th>Unit Price (Ghs)</th>
        <th>Availability</th>
        <th>Action</th>
      </tr>
    </thead>

    <tbody>
      {products.map((product, index) => {
        return (
          <tr className="border-b" key={index}>
            <th scope="row" class="px-6 font-medium text-gray-900 text-left">
              <p>{product.itemNumber}</p>
              <p className="text-gray-600 font-light text-sm">{product.id}</p>
            </th>
            <td className="py-7 px-6">
              <span className="p-4 rounded-sm">{capitalize(product.name)}</span>
            </td>
            <td>
              <span className=" px-3 py-1 rounded-sm text-gray-800">
                {product.price}
              </span>
            </td>
            <td>
              <span className=" px-3 py-1 rounded-sm">
                {product.isAvailable ? "Available" : "Not available"}
              </span>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div> */
}

{
  /* mobile */
}
{
  /* <div className="md:hidden flex flex-col gap-5">
  {products.map((product) => {
    return (
      <React.Fragment>
        <div className="flex flex-row gap-5 justify-between my-2">
          <div className="flex flex-col text-left gap-1">
            <div>
              <p className="text-base">{capitalize(product.name)}</p>
            </div>
            <div className="">
              <span className="text-sm">{product.itemNumber}</span>
              <p className="text-gray-600 font-light text-xs">#{product.id}</p>
            </div>
          </div>
        
          <div className="flex flex-col text-right gap-5">
            <div>
              <span className=" px-3 py-1 text-sm text-gray-800">
                {product.price} Ghs
              </span>
            </div>
            <div>
              <span className=" px-3 py-1 text-sm font-light">
                {product.isAvailable ? "Available" : "Not available"}
              </span>
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    );
  })}
</div>; */
}
