import React, { useState } from "react";

import capitalize from "../../../utils/Capitalize";

import { Disclosure } from "@headlessui/react";

import { IoIosArrowDown } from "react-icons/io";

import dayjs from "dayjs";
// dayjs localizes
var localizedFormat = require("dayjs/plugin/localizedFormat");

function BoxDetails({ selected }) {
  dayjs.extend(localizedFormat);

  const {
    customer,
    email,
    location,
    orderDate,
    orderStatus,
    paymentMethod,
    paymentStatus,
    phoneNumber,
    productsCost,
    shippingFee,
    products,
  } = selected;

  console.log("selected", selected);

  const [loading, setLoading] = useState(false);
  const updateOrderStatus = () => {
    //
  };

  const handleOrderUpdate = (e) => {
    //
    console.log("udpated to:", e.target.value);
  };
  return (
    <div>
      <div className="w-full">
        <div className="grid grid-cols-4 gap-8 mb-5">
          <div className="data-card">
            <span>Client</span>
            <p>{customer}</p>
          </div>
          <div className="data-card">
            <span>Email</span>
            <p>{email}</p>
          </div>
          <div className="data-card">
            <span>Client</span>
            <p>{phoneNumber}</p>
          </div>

          <div className="data-card">
            <span>Location</span>
            <p>{location}</p>
          </div>

          <div className="data-card">
            <span>Order Date & Time</span>
            <p>{dayjs(orderDate).format("LLL")}</p>
          </div>

          <div className="data-card">
            <span>Payment Method</span>
            <p>{paymentMethod.replaceAll("_", " ")}</p>
          </div>
          <div className="data-card">
            <span>Payment Status</span>
            <p>{paymentStatus}</p>
          </div>
          <div className="data-card">
            <span>Shipping Fee</span>
            <p>Ghs{shippingFee}</p>
          </div>
          <div className="data-card">
            <span>Order Status</span>
            <p>{orderStatus}</p>
          </div>
          <div className="data-card">
            <span>Products Cost (Total)</span>
            <p>Ghs{productsCost}</p>
          </div>
          <div className="data-card">
            <span>Products Quantity (Unit)</span>
            <p>{products.length}</p>
          </div>
        </div>

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Order Products</span>
                <IoIosArrowDown
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
                  {products.map((product) => {
                    return (
                      <div className="flex gap-10 mb-5">
                        <div>
                          {product.imageUrl.length > 10 ? (
                            <img
                              className="object-contain w-14 h-14 border border-gray-400 rounded-lg"
                              src={product.imageUrl}
                              alt="product"
                            />
                          ) : (
                            <div className="border border-gray-200 w-14 h-14 border-dashed rounded-lg flex items-center justify-center">
                              *
                            </div>
                          )}
                        </div>
                        <div>
                          <h4 className="text-lg font-semibold">
                            {capitalize(product.name)}
                          </h4>
                          <p>
                            Quantity: {product.quantity} | Unit Price:{" "}
                            {product.price}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full mt-5 justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Update Order Status</span>
                <IoIosArrowDown
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <div className="mb-20">
                  <fieldset>
                    <form
                      className="grid grid-cols-2 space-y-2"
                      onChange={handleOrderUpdate}
                    >
                      {/* PENDING */}
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="candidates"
                            aria-describedby="candidates-description"
                            name="paymentMethod"
                            value="PENDING"
                            type="radio"
                            className="h-4 w-4 accent-primary-200"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="candidates" className="font-medium ">
                            Pending
                          </label>
                        </div>
                      </div>

                      {/* PROCESSING */}
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="offers"
                            aria-describedby="offers-description"
                            name="paymentMethod"
                            type="radio"
                            value="PROCESSING"
                            className="h-4 w-4 accent-primary-200"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="offers" className="font-medium ">
                            Processing
                          </label>
                        </div>
                      </div>

                      {/* PROCESSING */}
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="offers"
                            aria-describedby="offers-description"
                            name="paymentMethod"
                            type="radio"
                            value="DELIVERED"
                            className="h-4 w-4 accent-primary-200"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="offers" className="font-medium ">
                            Delivered
                          </label>
                        </div>
                      </div>

                      {/* CANCELLED */}
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="offers"
                            aria-describedby="offers-description"
                            name="paymentMethod"
                            type="radio"
                            value="CANCELLED"
                            className="h-4 w-4 accent-primary-200"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="offers" className="font-medium ">
                            Cancelled
                          </label>
                        </div>
                      </div>
                    </form>
                  </fieldset>

                  {/* Button to update */}
                  <button
                    onClick={() => updateOrderStatus()}
                    disabled={loading}
                    className="btn-primary w-48 mt-10"
                  >
                    {loading ? "Updating..." : "Update Order Status"}
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

export default BoxDetails;
