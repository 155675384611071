import React from "react";

import { DataGrid } from "@mui/x-data-grid";

function Table({
  rows,
  columns,
  pagination = 30,
  sortField = "orderDate",
  sort = "desc",
}) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pagination,
          },
        },
        sorting: {
          sortModel: [{ field: sortField, sort: sort }],
        },
      }}
      getRowId={(row) => row.id}
      pageSizeOptions={[30]}
      checkboxSelection
      autoHeight={true}
      disableRowSelectionOnClick
    />
  );
}

export default Table;
