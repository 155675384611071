import React, { useState } from "react";

import services from "../../../services";

import Notify from "../../../components/Notifications/Notify";

function NewAdmin() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  //
  const [loading, setLoading] = useState(false);

  const createAdmin = (e) => {
    //
    e.preventDefault();
    setLoading(true);
    console.log(firstName, lastName, email);
    services
      .createNewAdmin({ firstName, lastName, email })
      .then((res) => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        Notify("success", res.data.message);
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", e?.response.data.error);
      });
  };
  return (
    <div>
      <form className="max-w-xl">
        <h4>Create New Administrator</h4>
        <p className="font-light  text-sm mb-4 mt-1">
          * A password reset email would be sent to the admin
        </p>
        <div className="flex flex-col justify-between gap-5 mb-2">
          <div className="w-full">
            {" "}
            <label className="prod-label">FirstName</label>
            <input
              className="form-input-outline"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="w-full">
            {" "}
            <label className="prod-label">Last Name</label>
            <input
              className="form-input-outline"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="w-full">
            {" "}
            <label className="prod-label">FirstName</label>
            <input
              className="form-input-outline"
              value={email}
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <button
          onClick={(e) => createAdmin(e)}
          disabled={loading || firstName.length < 2 || email.length < 5}
          className="btn-primary text-base font-normal px-5 w-56"
        >
          {loading ? "Creating..." : "Create Admin"}
        </button>
      </form>
    </div>
  );
}

export default NewAdmin;
