import React, { useState, useEffect } from "react";

import Layout from "../../components/Layout/Layout";

import Table from "../../components/Table/Table";

import SERVICES from "../../services";

import Loader from "../../components/Loader/Loader";

function Clients() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SERVICES.getClients()
      .then((res) => {
        setLoading(false);
        setClients(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const columns = [
    { field: "businessName", headerName: "Business Name", width: 200 },
    { field: "correspondent", headerName: "Correspondent", width: 150 },
    { field: "location", headerName: "Location", width: 200 },
    { field: "number", headerName: "Phone Number", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
  ];

  let rows = [];
  if (!loading) {
    clients.forEach((client) => {
      rows.push({
        id: client.id,
        businessName: client.businessName,
        correspondent: client.firstName + " " + client.lastName,
        location: client.location,
        number: client.phoneNumber,
        email: client.email,
      });
    });
  }

  return (
    <Layout>
      <div> Clients</div>

      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Table rows={rows} columns={columns} />
        </React.Fragment>
      )}
    </Layout>
  );
}

export default Clients;
