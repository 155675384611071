import React, { Fragment, useState, useEffect, useContext } from "react";

import Layout from "../../components/Layout/Layout";

import { AuthContext } from "../../contexts/AuthContext";

import Admins from "./components/Admins";

import { Tab } from "@headlessui/react";
import Profile from "./components/Profile";
import NewAdmin from "./components/NewAdmin";

function Settings() {
  const { user } = useContext(AuthContext);

  const [role, setRole] = useState(user[0].role);

  return (
    <Layout>
      <div> Settings</div>
      <Tab.Group>
        <Tab.List className="tab-list">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button className={selected ? "tab-selected" : "tab-normal"}>
                Your Profile
              </button>
            )}
          </Tab>
          {role === "SUPER_ADMIN" && (
            <Fragment>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button className={selected ? "tab-selected" : "tab-normal"}>
                    All Admins
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button className={selected ? "tab-selected" : "tab-normal"}>
                    Create New Admin
                  </button>
                )}
              </Tab>
            </Fragment>
          )}
        </Tab.List>
        <Tab.Panels className="my-5">
          <Tab.Panel>
            <Profile user={user[0]} />
          </Tab.Panel>
          <Tab.Panel>
            <Admins />
          </Tab.Panel>
          <Tab.Panel>
            <NewAdmin />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}

export default Settings;
