import React, { Fragment, useEffect, useState } from "react";

import { Tab } from "@headlessui/react";

import Loader from "../../components/Loader/Loader";

import Layout from "../../components/Layout/Layout";
import services from "../../services";

import AnalyticsCard from "../../components/Analytics/Card";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState(null);

  useEffect(() => {
    setLoading(true);
    setOverview(null);
    services
      .overviewSummary()
      .then((res) => {
        setOverview(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, []);

  return (
    <Layout>
      <div> Overview </div>
      <Tab.Group>
        <div class="border-b border-gray-200 mt-2">
          <Tab.List class="flex -mb-px ">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Lifetime Statistics</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Orders Summary</p>
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels className="mt-7">
          {/* Statistics */}
          <Tab.Panel>
            {loading || overview === null ? (
              <Loader />
            ) : (
              <React.Fragment>
                <div className="grid grid-cols-4 gap-10">
                  <AnalyticsCard
                    title={"Number of Orders"}
                    body={overview.data.numberOfOrders}
                    col={1}
                  />

                  {/* Completed */}
                  <AnalyticsCard
                    title={"Total Orders"}
                    body={`Ghs${overview.data.totalOrderCosts}`}
                    col={2}
                  />

                  {/* Cancelled */}
                  <AnalyticsCard
                    title={"Total Shipping Fees"}
                    body={`Ghs${overview.data.totalShippingFees}`}
                    col={3}
                  />
                </div>
              </React.Fragment>
            )}
          </Tab.Panel>

          {/* Order summary */}
          <Tab.Panel>
            {loading || overview === null ? (
              <Loader />
            ) : (
              <React.Fragment>
                <div className="grid grid-cols-4 gap-10">
                  <AnalyticsCard
                    title={"Pending Orders"}
                    body={overview.data.pendingOrders.length}
                    col={1}
                  />

                  {/* Completed */}
                  <AnalyticsCard
                    title={"Completed Orders"}
                    body={overview.data.completedOrders.length}
                    col={2}
                  />

                  {/* Cancelled */}
                  <AnalyticsCard
                    title={"Cancelled Orders"}
                    body={overview.data.cancelledOrders.length}
                    col={3}
                  />
                </div>
              </React.Fragment>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}

export default Dashboard;
