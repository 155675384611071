import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AnalyticsCard = ({ title, body, col }) => {
  return (
    <div className=" bg-white border border-gray-300 shadow-sm rounded-[7px]">
      <div className="w-10/12 mx-auto py-6">
        <div
          className={classNames(
            col === 3 ? "bg-[#FFC3C3]" : "",
            col === 2 ? "bg-[#C3FFCD]" : "",
            col === 1 ? "bg-[#C3F1FF]" : "",
            col === 0 ? "bg-[#DEC3FF]" : "",
            "w-11 h-8 flex justify-center items-center rounded-md"
          )}
        ></div>
        <div className="pt-4">
          <h2 className=" text-secondary-200 text-3xl font-semibold mb-4">
            {body}
          </h2>
          <div className="w-full border"></div>
          <p className="text-base font-medium text-gray-800 mt-5">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
