import React from "react";
import ReactDOM from "react-dom/client";

// antd for table
import "antd/dist/reset.css";

// last css to be applied is tailwind
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
