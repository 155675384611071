import React, { useState, useContext, useEffect, createContext } from "react";

export const AuthContext = createContext();

const dataFromSession = JSON.parse(sessionStorage.getItem("user") || "[]");

export function useAuth() {
  return useContext(AuthContext);
}

const AuthContextProvider = (props) => {
  // retrieving values from session storage
  const [user, setUser] = useState(dataFromSession);

  // adding user details to session
  const addUser = (user) => {
    setUser([
      {
        ...user,
      },
    ]);
  };

  // deleting/destroying user details upon logout
  const removeUser = (user) => {
    sessionStorage.clear();
    window.location.reload();
    return false;
  };

  // useEffect to update Session storage
  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, addUser, removeUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
