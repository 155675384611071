import React, { useState } from "react";

import { Switch } from "@headlessui/react";

import Notify from "../../components/Notifications/Notify";

import services from "../../services/index";

function ProductDetails({ selectedProduct, change, setChange, setIsOpen }) {
  const [name, setName] = useState(selectedProduct.name);
  const [itemNumber, setItemNumber] = useState(selectedProduct.itemNumber);
  const [desc, setDesc] = useState(selectedProduct.description);
  const [price, setPrice] = useState(selectedProduct.price);
  const [available, setAvailable] = useState(selectedProduct.isAvailable);
  const [visible, setVisible] = useState(selectedProduct.isVisible);
  const [image, setImage] = useState(selectedProduct.imageUrl);

  //
  const [loading, setLoading] = useState(false);

  const editProduct = () => {
    setLoading(true);
    let data = {
      id: selectedProduct.id,
      name,
      price,
      isAvailable: available,
      isVisible: visible,
      description: desc,
      imageUrl: "",
    };
    services
      .editProduct(data)
      .then((res) => {
        setLoading(false);
        setChange(!change);
        setIsOpen(false);
        Notify("success", "Edited successfully");
      })
      .catch((e) => {
        setIsOpen(false);
        setLoading(false);
        Notify("error", "An error occured");
      });
  };

  return (
    <div className="p-4">
      {/* Item number */}
      <label className="prod-label">Item Number</label>
      <h4 className="text-xl mb-4">{itemNumber}</h4>

      {/*  */}
      <div className="flex justify-between gap-10 mb-2">
        <div className="w-full">
          {" "}
          <label className="prod-label">Product Name</label>
          <input
            className="form-input-outline"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="w-full">
          {" "}
          <label className="prod-label">Price (Ghs)</label>
          <input
            className="form-input-outline"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>

      {/* description */}
      <div className="flex justify-between gap-10">
        <div className="w-full">
          {" "}
          <label className="prod-label">Description</label>
          <textarea
            rows={4}
            className="form-input-outline"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </div>

      {/* available and visible */}
      <div className="flex justify-between gap-10 mt-5 mb-2">
        <div className="w-full">
          {" "}
          <label className="prod-label">Availability</label>
          <Switch
            checked={available}
            onChange={setAvailable}
            className={`${available ? "bg-primary-200" : "bg-gray-500"}
          relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${available ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>

        <div className="w-full">
          {" "}
          <label className="prod-label">Visibility</label>
          <Switch
            checked={visible}
            onChange={setVisible}
            className={`${visible ? "bg-primary-200" : "bg-gray-500"}
          relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${visible ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>

      {/* image */}
      <div className="my-5">
        {" "}
        <label className="prod-label">Image</label>
        {image.length > 10 ? (
          <>
            <img
              className="rounded-lg w-32 h-32 object-contain border border-gray-400"
              src={image}
              alt="product "
            />
          </>
        ) : (
          <>
            <p>No product image</p>
          </>
        )}
      </div>

      <button
        onClick={() => editProduct()}
        disabled={loading}
        className="btn-primary w-48"
      >
        {loading ? "Saving..." : "Save Changes"}
      </button>
    </div>
  );
}

export default ProductDetails;
