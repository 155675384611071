import React, { Fragment, useState } from "react";

import Layout from "../../components/Layout/Layout";

import { GridActionsCellItem } from "@mui/x-data-grid";

import Modal from "../../components/Modal/Modal";

import { AiOutlineEye } from "react-icons/ai";

import { Tab } from "@headlessui/react";

// dayjs

import AllBoxes from "./components/AllBoxes";
import PendingBoxes from "./components/PendingBoxes";
import CancelledBoxes from "./components/CancelledBoxes";
import CompletedBoxes from "./components/CompletedBoxes";

import BoxDetails from "./components/BoxDetails";

import "./index.css";

import dayjs from "dayjs";
// dayjs localizes
var localizedFormat = require("dayjs/plugin/localizedFormat");

function Boxes() {
  dayjs.extend(localizedFormat);

  const [show, setShow] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(null);

  const [change, setChange] = useState(false);

  const columns = [
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 220,
      valueFormatter: (params) => dayjs(params.value).format("LLL"),
    },
    { field: "businessName", headerName: "Client", width: 200 },
    { field: "products", headerName: "Products", width: 100 },
    { field: "totalCost", headerName: "Total Cost", width: 100 },
    { field: "orderStatus", headerName: "Order Status", width: 100 },
    // { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    { field: "paymentStatus", headerName: "Payment Status", width: 150 },
    {
      field: "actions",
      headerName: "Details",
      width: 80,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AiOutlineEye />}
          label="Edit"
          onClick={() => {
            setShow(true);
            setSelected(params.row.actions);
          }}
        />,
      ],
    },
  ];

  let data = [];

  if (!loading && boxes) {
    boxes.forEach((box) => {
      data.push({
        id: box.id,
        boxId: box.id,
        orderDate: box.orderDate,
        //orderDate: dayjs(box.orderDate).format("LLL"),
        businessName: box.customer,
        products: box.products.length,
        totalCost: `Ghs ${box.productsCost}`,
        orderStatus: box.orderStatus,
        paymentMethod: box.paymentMethod,
        paymentStatus: box.paymentStatus,
        actions: box,
      });
    });
  }

  return (
    <Layout>
      <div> Boxes</div>

      <Tab.Group>
        <div class="border-b border-gray-200 mt-2">
          <Tab.List class="flex -mb-px ">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Pending Boxes</p>
                </button>
              )}
            </Tab>

            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Cancelled Orders</p>
                </button>
              )}
            </Tab>

            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Completed Orders</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>All Orders</p>
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels className="mt-7">
          {/* Pending */}
          <Tab.Panel>
            <PendingBoxes
              setBoxes={setBoxes}
              data={data}
              loading={loading}
              setLoading={setLoading}
              columns={columns}
            />
          </Tab.Panel>

          {/* Cancelled */}
          <Tab.Panel>
            <CancelledBoxes
              setBoxes={setBoxes}
              data={data}
              loading={loading}
              setLoading={setLoading}
              columns={columns}
            />
          </Tab.Panel>
          <Tab.Panel>
            <CompletedBoxes
              setBoxes={setBoxes}
              data={data}
              loading={loading}
              setLoading={setLoading}
              columns={columns}
            />
          </Tab.Panel>

          {/* all boxes */}
          <Tab.Panel>
            <AllBoxes
              setBoxes={setBoxes}
              data={data}
              loading={loading}
              setLoading={setLoading}
              columns={columns}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* Order Modal */}
      <Modal title="Venda Box Details" isOpen={show} setIsOpen={setShow}>
        <BoxDetails
          change={change}
          setChange={setChange}
          setIsOpen={setShow}
          selected={selected}
        />
      </Modal>
    </Layout>
  );
}

export default Boxes;
