import React, { useState, useEffect } from "react";

import Notify from "../../../components/Notifications/Notify";

// import { xlsx } from 'xlsx'

import { utils, read } from "xlsx";

import services from "../../../services";
import Loader from "../../../components/Loader/Loader";

function BulkActions({ change, setChange }) {
  const [allCategories, setAllCategories] = useState(null);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState(null);

  useEffect(() => {
    Notify("info", "Hold on. Fetching categories....");
    //
    setLoading(true);
    services
      .categories()
      .then((res) => {
        setLoading(false);
        setAllCategories(res.data.data);
      })
      .catch((e) => {
        // setLoading(false);
        Notify("error", "Error getting categories");
      });
  }, []);

  const readUploadFile = (e) => {
    e.preventDefault();
    let rawProducts = [];
    let finalProducts = [];
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setProducts(json);
        rawProducts = json;

        // map human categories to backend ids then assign
        for (let i = 0; i < rawProducts.length; i++) {
          // find id of category
          for (let j = 0; j < allCategories.length; j++) {
            if (
              allCategories[j].name.toLowerCase() ===
              rawProducts[i].category.toLowerCase()
            ) {
              finalProducts.push({
                ...rawProducts[i],
                category: allCategories[j].id,
              });
              break;
            }
          }
        }

        // set to products
        setProducts(finalProducts);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const uploadProducts = () => {
    setLoading(true);
    Notify("info", `Uploading ${products.length} products...`);

    services
      .createBulkProducts(products)
      .then((res) => {
        Notify("success", "Upload Successful");
        setProducts(null);
        setLoading(false);
        setChange(!change);
      })
      .catch((e) => {
        Notify("error", "An error occured");
        setProducts(null);
        setLoading(false);
      });
  };

  return (
    <div>
      <div>Bulk Upload</div>
      <p className="mb-10 max-w-2xl text-gray-400 text-sm font-light">
        *Ensure that the excel sheet has no more than 200 rows, and headers in
        this arrangment: The headers are case sensitive so make sure they are
        exactly as shown below <br />
        <div className="my-1 text-black text-lg font-semibold">
          name, price, itemNumber, description, isAvailabile, isVisible,
          category, imageUrl
        </div>
      </p>
      {loading ? (
        <Loader />
      ) : (
        <div className="inline-block relative font-normal text-sm mb-10 border border-dashed border-gray p-10 text-center mx-auto rounded-xl">
          <form>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={readUploadFile}
            />
          </form>
        </div>
      )}

      {/* upload */}
      <div>
        <button
          disabled={!products || loading}
          className="btn-primary text-base font-normal px-5 w-56"
          onClick={() => uploadProducts()}
        >
          Upload
        </button>
      </div>
    </div>
  );
}

export default BulkActions;
