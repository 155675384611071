import React, { useEffect, useState } from "react";

import SERVICES from "../../../services";

import Table from "../../../components/Table/Table";

import Loader from "../../../components/Loader/Loader";

import Notify from "../../../components/Notifications/Notify";

function CancelledBoxes({ columns, loading, data, setLoading, setBoxes }) {
  useEffect(() => {
    setLoading(true);
    SERVICES.overviewSummary()
      .then((res) => {
        setBoxes(res.data.data.cancelledOrders);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Error getting boxes");
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Table rows={data} columns={columns} />
        </React.Fragment>
      )}
    </div>
  );
}

export default CancelledBoxes;
