import React, { useState, useEffect } from "react";
import services from "../../../services";

import { AiOutlineEye } from "react-icons/ai";

import Modal from "../../../components/Modal/Modal";

import Loader from "../../../components/Loader/Loader";

import Table from "../../../components/Table/Table";

import { GridActionsCellItem } from "@mui/x-data-grid";
import Notify from "../../../components/Notifications/Notify";

function Categories() {
  const [allCategories, setAllCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    //
    setLoading(true);
    services
      .categories()
      .then((res) => {
        setLoading(false);
        setAllCategories(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Error getting categories");
      });
  }, [change]);

  const [createLoad, setCreateLoad] = useState(false);

  const createCategory = () => {
    setCreateLoad(true);
    services
      .createCategory(name)
      .then((res) => {
        Notify("success", "Created category successfully");
        setCreateLoad(false);
        setChange(!change);
        setName("");
      })
      .catch((e) => {
        setCreateLoad(false);
        Notify("error", "Error creating category");
      });
  };

  const [editLoad, setEditLoad] = useState(false);

  const editCategory = () => {
    setEditLoad(true);
    services
      .editCategory(selected.id, editName)
      .then((res) => {
        Notify("success", "Edited");

        setChange(!change);
        setEditName("");
        setShow(false);
        setEditLoad(false);
      })
      .catch((e) => {
        setEditLoad(false);
        setShow(false);
        Notify("error", "Error editing");
      });
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "actions",
      headerName: "Action",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AiOutlineEye />}
          label="Edit"
          onClick={() => {
            setShow(true);
            setSelected(params.row);
            setEditName(params.row.name);
          }}
        />,
      ],
    },
  ];

  let data = [];
  if (!loading && allCategories) {
    allCategories.forEach((cat) => {
      data.push({
        ...cat,
      });
    });
  }

  return (
    <div>
      <p className="text-lg font-semibold mb-5"> Create New Category</p>
      <div className="max-w-2xl mb-10 flex gap-5">
        {" "}
        <input
          className="form-input-outline"
          value={name}
          placeholder="New Category"
          onChange={(e) => setName(e.target.value)}
        />
        <div>
          <button
            disabled={name.length < 3 || createLoad}
            onClick={() => createCategory()}
            className="btn-primary text-base font-normal w-32"
          >
            {createLoad ? "Hang on..." : "Create"}
          </button>
        </div>
      </div>

      {/* All Categories */}
      <div className="mb-5">
        <p className="text-lg font-semibold"> All Categories</p>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <Table rows={data} columns={columns} />
        </div>
      )}

      {/* Edit Category */}
      <Modal title="Edit Category" isOpen={show} setIsOpen={setShow}>
        <div className="max-w-2xl mb-10 flex gap-5">
          {" "}
          <input
            className="form-input-outline"
            value={editName}
            placeholder="New Category"
            onChange={(e) => setEditName(e.target.value)}
          />
          <div>
            <button
              disabled={editName.length < 3 || editLoad}
              onClick={() => editCategory()}
              className="btn-primary text-base font-normal w-32"
            >
              {editLoad ? "Hang on..." : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Categories;
