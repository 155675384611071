import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// toast messages
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

// auth context provider
import AuthContextProvider from "./contexts/AuthContext";

// offline online
import { Offline } from "react-detect-offline";

// app pages import
import Login from "./app/Login/Login";
import Dashboard from "./app/Dashboard/Dashboard";
import Settings from "./app/Settings/Settings";
import Clients from "./app/Clients/Clients";
import Products from "./app/Products/Products";
import Boxes from "./app/Boxes/Boxes";
import ResetPassword from "./app/ResetPassword/ResetPassword";

//import NotFound from "./app/notfound/NotFound";

function App() {
  // fake authentication Promise
  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000)); // 2 seconds
  };

  useEffect(() => {
    authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = "";
        }, 2000);
      }
    });
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Offline>
          <div className="w-full bg-indigo-800 text-white text-center tracking-wide pt-3 pb-3 pl-3 pr-3">
            It seems that your device is offline. Please, check your internet
            connection and try again
          </div>
        </Offline>
      </div>
      <AuthContextProvider>
        <Routes>
          {/* NON AUTH ROUTES */}
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />

          {/* pages */}
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/settings" exact element={<Settings />} />
          <Route path="/boxes" exact element={<Boxes />} />
          <Route path="/products" exact element={<Products />} />
          <Route path="/clients" exact element={<Clients />} />
          <Route
            path="/auth/validate/admin/:id/:id/"
            exact
            element={<ResetPassword />}
          />

          {/* AUTH / PRIVATE ROUTES */}

          {/* PAGE NOT FOUND */}
          {/* <Route path="*" component={NotFound} /> */}
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
