import React, { useEffect, useState } from "react";

import SERVICES from "../../../services";

import Table from "../../../components/Table/Table";

import Loader from "../../../components/Loader/Loader";

import Notify from "../../../components/Notifications/Notify";

function PendingBoxes({ columns, setLoading, setBoxes, loading, data }) {
  useEffect(() => {
    setLoading(true);
    SERVICES.overviewSummary()
      .then((res) => {
        setBoxes(res.data.data.pendingOrders);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Error getting boxes");
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Table
            sortField="orderDate"
            pagination="20"
            rows={data}
            columns={columns}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default PendingBoxes;
