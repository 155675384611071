import React from "react";

// import load from "../../assets/icons/load.svg";

import { ScaleLoader } from "react-spinners";

function Loader() {
  return (
    <div className="h-[50vh] flex items-center justify-center">
      <div className="text-center">
        {/* <img src={load} alt="loading" /> */}
        <ScaleLoader height={40} width={5} color="#2F0247" />
        <p className="font-light text-sm"> Loading... </p>
      </div>
    </div>
  );
}

export default Loader;
