/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";

// linnk
import { NavLink, Navigate } from "react-router-dom";

// logo import
// import Logo from "../../assets/images/logo-dark.png";

// import auth context
import { AuthContext } from "../../contexts/AuthContext";

// icons
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { GoSignOut, GoSettings } from "react-icons/go";
import { BsBoxSeam } from "react-icons/bs";
import { BiUserPin } from "react-icons/bi";
import { RiShoppingCartLine } from "react-icons/ri";

function Layout({ children }) {
  const [mobile, setMobile] = useState(false);

  // auth
  // auth context
  const { user, removeUser } = useContext(AuthContext);

  const toggleSideNav = () => {
    setMobile(!mobile);
  };

  if (user.length === 0) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="relative min-h-screen md:flex">
        {/* <!-- mobile menu bar --> */}
        <div className="bg-secondary-200 text-gray-100 flex justify-between md:hidden">
          {/* <!-- logo --> */}
          <a href="#" className="block p-4 text-white font-bold">
            <p>Management</p>
          </a>

          {/* <!-- mobile menu button --> */}
          <button
            onClick={() => toggleSideNav()}
            className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* <!-- sidebar --> */}
        <div
          className={`flex flex-col  justify-between sidebar bg-secondary-400 ${
            mobile ? "" : "-translate-x-full"
          } w-80 z-40 space-y-6 py-7 h-screen absolute md:fixed md:translate-x-0 inset-y-0 left-0 transform   transition duration-200 ease-in-out`}
        >
          {/* logo and main nav */}
          <div className="divide-y">
            {/* <!-- logo --> */}
            <a
              href="#"
              className="text-white flex items-center space-x-2 px-10"
            >
              <h1 className="text-2xl ">Venda</h1>
            </a>

            {/* <!-- nav --> */}
            <nav className="mt-7 px-6">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : "nav-link"
                }
              >
                <MdOutlineSpaceDashboard
                  className={({ isActive }) =>
                    isActive ? "nav-active-icon" : "nav-icon"
                  }
                  size={25}
                />
                <span className="inline align-middle ml-3">Overview</span>
              </NavLink>
              <NavLink
                to="/products"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : "nav-link"
                }
              >
                <RiShoppingCartLine
                  size={25}
                  className={({ isActive }) =>
                    isActive ? "nav-active-icon" : "nav-icon"
                  }
                />
                <span className="inline align-middle ml-3">Products</span>
              </NavLink>
              <NavLink
                to="/boxes"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : "nav-link"
                }
              >
                <BsBoxSeam
                  size={25}
                  className={({ isActive }) =>
                    isActive ? "nav-active-icon" : "nav-icon"
                  }
                />
                <span className="inline align-middle ml-3">Boxes</span>
              </NavLink>
              <NavLink
                to="/clients"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : "nav-link"
                }
              >
                <BiUserPin
                  size={25}
                  className={({ isActive }) =>
                    isActive ? "nav-active-icon" : "nav-icon"
                  }
                />
                <span className="inline align-middle ml-3">Clients</span>
              </NavLink>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : "nav-link"
                }
              >
                <GoSettings
                  size={25}
                  className={({ isActive }) =>
                    isActive ? "nav-active-icon" : "nav-icon"
                  }
                />
                <span className="inline align-middle ml-3">Settings</span>
              </NavLink>
            </nav>
          </div>
          {/* logout button */}
          <div>
            {" "}
            <NavLink to="/" onClick={() => removeUser()} className="nav-link">
              <GoSignOut
                className={({ isActive }) =>
                  isActive ? "nav-active-icon" : "nav-icon"
                }
                size={25}
              />
              <span className="inline align-middle ml-3">Log Out</span>
            </NavLink>
          </div>
        </div>

        {/* <!-- content --> */}
        <div className="flex-1 ml-0 md:ml-80 p-10 text-2xl font-bold">
          {children}
        </div>
      </div>
    );
  }
}

export default Layout;
