import React from "react";

function Profile({ user }) {
  return (
    <div>
      <span className="desc">Name</span>
      <p>
        {user.firstName}, {user.lastName}
      </p>

      <span className="desc">Email</span>
      <p>{user.email}</p>
    </div>
  );
}

export default Profile;
