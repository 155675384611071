// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCLz4ypiKHXyc0pFikZ4QEa_GCs75_e_M",
  authDomain: "shop-venda.firebaseapp.com",
  projectId: "shop-venda",
  storageBucket: "shop-venda.appspot.com",
  messagingSenderId: "685097679897",
  appId: "1:685097679897:web:4271864457fbe7bd2e3e88",
  measurementId: "G-1C1R8TZE1L",
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Storage
export const storage = getStorage(app);
