import React, { useState, useContext } from "react";

import Notify from "../../components/Notifications/Notify";

import capitalize from "../../utils/Capitalize";

// Link
import { useNavigate } from "react-router-dom";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import SERVICES from "../../services";

import woman from "../../assets/images/woman.png";

import { AuthContext } from "../../contexts/AuthContext";

function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // auth context
  const { addUser } = useContext(AuthContext);

  let navigate = useNavigate();

  const handleSignIn = () => {
    setLoading(true);
    //creds
    //"email": "superadmin@venda.com",
    //  "password": "password"

    SERVICES.login({ email, password })
      .then((res) => {
        setLoading(false);
        Notify("success", capitalize(res.data.message));
        addUser(res.data.data);
        navigate("/boxes");
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Error signing in");
      });
  };

  return (
    <div className="flex flex-col md:flex-row bg-gray-100 justify-around gap-10 md:gap-20 md:items-center h-screen p-3 md:p-20">
      <div className="flex-1">
        <img className="rounded-2xl" src={woman} alt="business woman" />
      </div>
      <div className="flex-1 flex flex-col h-full justify-between">
        <h3 className="text-lg md:text-2xl font-bold text-secondary-200">
          Venda
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSignIn();
          }}
          className=""
        >
          <h1 className="text-3xl md:text-4xl font-bold mb-5 md:mb-8">
            Management Log In
          </h1>
          {/* EMAIL ADDRESS */}
          <div className="flex">
            <div className="w-full">
              <input
                required
                className="form-input-full"
                type="text"
                placeholder="Email Address"
                value={email}
                onFocus={() => {}}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          {/* PASSWORD */}
          <div className="flex">
            <div style={{ position: "relative" }} className="w-full">
              <input
                required
                className="form-input-full"
                type={passwordVisible ? "input" : "password"}
                placeholder="Password"
                value={password}
                onFocus={() => {}}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 20,
                  right: 32,
                }}
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </div>
            </div>
          </div>
          <button
            disabled={loading}
            className="bg-primary-400 btn-primary  w-full mt-3"
          >
            {loading ? "Please wait..." : "Log In"}
          </button>
        </form>
        <p className="font-light text-sm">
          Email support at{" "}
          <span className="font-bold text-secondary-200">
            hello@vendasupply.com
          </span>
        </p>
      </div>
    </div>
  );
}

export default Login;
