import React, { useState, useEffect, Fragment } from "react";

import { Switch } from "@headlessui/react";

import { Listbox, Transition } from "@headlessui/react";

import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import services from "../../../services";

import { IoIosArrowDown } from "react-icons/io";

import Notify from "../../../components/Notifications/Notify";

import { AiFillCloseCircle } from "react-icons/ai";

function Create({ change, setChange }) {
  const [name, setName] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState(null);
  const [available, setAvailable] = useState(true);
  const [visible, setVisible] = useState(true);

  // image stuff
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(" ");
  const [progress, setProgress] = useState(0);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ id: 1 });

  //
  const [loading, setLoading] = useState(false);

  const createProduct = () => {
    // upload file first
    if (!file) return;

    setLoading(true);
    const storageRef = ref(storage, `/products/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        Notify("error", err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // Store name and url in database
          // Create Product here
          setImage(url);
          let data = {
            name,
            price,
            itemNumber,
            description: desc,
            isAvailable: available,
            isVisible: visible,
            category: selectedCategory.id,
            imageUrl: url,
          };
          services
            .createProduct(data)
            .then((res) => {
              setLoading(false);

              Notify("success", "Created product");
              setChange(!change);
              // reset everything
              setName("");
              setPrice("");
              setItemNumber("");
              setDesc("");
              setFile(null);
              setImage(" ");
              setSelectedCategory({ id: 1 });
              window.scrollTo(0, 0);
            })
            .catch((e) => {
              setLoading(false);
              Notify("error", "An error occured");
              console.log("error editing", e);
            });
        });
      }
    );
  };

  // get all categories
  useEffect(() => {
    //
    setLoading(true);
    services
      .categories()
      .then((res) => {
        setLoading(false);
        setCategories(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Error getting categories");
      });
  }, []);

  return (
    <div className="p-4">
      {/* name and price  */}
      <div className="flex justify-between gap-10 mb-2">
        <div className="w-full">
          {" "}
          <label className="prod-label">Product Name</label>
          <input
            className="form-input-outline"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="w-full">
          {" "}
          <label className="prod-label">Price (Ghs)</label>
          <input
            className="form-input-outline"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>

      {/* Item number */}
      <div className="flex justify-between gap-10 mb-2">
        <div className="w-full">
          {" "}
          <label className="prod-label">Item Number</label>
          <input
            className="form-input-outline"
            value={itemNumber}
            onChange={(e) => setItemNumber(e.target.value)}
          />
        </div>

        <div className="w-full">
          <label className="prod-label">Product Category</label>
          <Listbox value={selectedCategory} onChange={setSelectedCategory}>
            <div className="relative">
              <Listbox.Button className="form-input-outline">
                <span className="block truncate">{selectedCategory.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <IoIosArrowDown />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {categories.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-primary-100 text-primary-400"
                            : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>

      {/* description */}
      <div className="flex justify-between gap-10">
        <div className="w-full">
          {" "}
          <label className="prod-label">Description</label>
          <textarea
            rows={4}
            className="form-input-outline"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </div>

      {/* available and visible */}
      <div className="flex justify-between gap-10 mt-5 mb-2">
        <div className="w-full">
          {" "}
          <label className="prod-label">Availability</label>
          <Switch
            checked={available}
            onChange={setAvailable}
            className={`${available ? "bg-primary-200" : "bg-gray-500"}
          relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${available ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>

        <div className="w-full">
          {" "}
          <label className="prod-label">Visibility</label>
          <Switch
            checked={visible}
            onChange={setVisible}
            className={`${visible ? "bg-primary-200" : "bg-gray-500"}
          relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${visible ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>

      {/* image */}
      <div className="my-5 mb-14">
        {" "}
        <label className="prod-label">Product Image</label>
        <div className="inline-block relative font-normal text-sm mb-10 border border-dashed border-gray p-10 text-center mx-auto rounded-xl">
          <div
            onClick={() => setFile(null)}
            className="absolute top-2 right-4 cursor-pointer"
          >
            <AiFillCloseCircle onClick={() => setFile(null)} size={18} />
          </div>
          {file ? (
            <>
              <img
                className="rounded-lg w-48 h-32 object-contain border border-gray-400"
                src={URL.createObjectURL(file)}
                alt="product "
              />
            </>
          ) : (
            <>
              <input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                placeholder="Product Image"
              />
            </>
          )}
        </div>
      </div>

      <button
        onClick={() => createProduct()}
        disabled={
          loading || name.length < 2 || price < 1 || selectedCategory.id === 1
        }
        className="btn-primary text-base font-normal px-5 w-56"
      >
        {loading ? "Creating..." : "Create Product"}
      </button>
    </div>
  );
}

export default Create;
