export const saveToken = (token) => {
  try {
    localStorage.setItem("t@k3n", token);
  } catch (e) {
    console.log("Error saving token");
  }
};

export const saveCredentials = (email, password) => {
  localStorage.setItem(
    "cr3d3ntials",
    JSON.stringify({
      email,
      password,
    })
  );
};

export const removeCred = () => {
  localStorage.removeItem("cr3d3ntials");
};

export const getCredentials = () => {
  const cred = localStorage.getItem("cr3d3ntials");

  if (cred === null) {
    return null;
  }
  return JSON.parse(cred);
};

export const getToken = () => {
  let user = JSON.parse(sessionStorage.getItem("user"));
  if (user === null) {
    // user is null so forget
    return 0;
  } else {
    if (user.length > 0) {
      return user[0].accessToken;
    } else {
      return 0;
    }
  }
};
