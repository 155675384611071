import axios from "axios";

import { getToken } from "./localService";

// CONSTANTS

// base url
const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

// headers
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
};

// ENDPOINTS

export const login = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/auth/admins/login`,
    data: data,
    headers: headers,
  });
};

export const getAdmins = () => {
  return axios({
    method: "GET",
    url: `${baseURL}/auth/admins`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const getClients = (page = 0, count = 100) => {
  return axios({
    method: "GET",
    url: `${baseURL}/auth/customers?page=${page}&count=${count}`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const getBoxes = (page = 0, count = 100) => {
  return axios({
    method: "GET",
    url: `${baseURL}/boxes?page=${page}&count=${count}`,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};

export const getProducts = (page = 0, count = 500) => {
  return axios({
    method: "GET",
    url: `${baseURL}/products?page=${page}&count=${count}`,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};

export const createProduct = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/products`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};

export const createBulkProducts = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/products/bulk`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};

export const editProduct = (data) => {
  return axios({
    method: "PUT",
    url: `${baseURL}/products/${data.id}`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const overviewSummary = () => {
  return axios({
    method: "GET",
    url: `${baseURL}/overview/summary`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const categories = () => {
  return axios({
    method: "GET",
    url: `${baseURL}/categories`,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const createCategory = (name) => {
  return axios({
    method: "POST",
    url: `${baseURL}/categories`,
    data: {
      categoryName: name,
    },
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const editCategory = (id, name) => {
  return axios({
    method: "PATCH",
    url: `${baseURL}/categories/${id}`,
    data: {
      categoryName: name,
    },
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const updateOrderStatus = (customerId, boxId, data) => {
  return axios({
    method: "PATCH",
    url: `${baseURL}/boxex/${customerId}/${boxId}`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
};

export const createNewAdmin = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/auth/admins`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};

export const setAdminPassword = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/auth/admins/reset`,
    data,
    headers: {
      authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
    },
  });
};
