import React, { useState } from "react";

import services from "../../services";

import Notify from "../../components/Notifications/Notify";

function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const [old, setOld] = useState("");
  const [newPass, setNewPass] = useState("");

  const setPassword = (e) => {
    //
    e.preventDefault();
    setLoading(true);

    let data = window.location.pathname.split("/");

    const email = data[4];
    const magic = data[5];

    services
      .setAdminPassword({
        email,
        magicLink: magic,
        oldPassword: old,
        newPassword: newPass,
      })
      .then((res) => {
        console.log(":res", res);
        Notify("Password Set! Login to continue");
        Notify("success", res.data.message);
        setLoading(false);
        setOld("");
        setNewPass("");
        window.location.assign("/");
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", e?.response.data.error);
      });
  };
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <form className="mx-auto w-[40vw] max-w-3xl">
        <h4 className="text-2xl font-bold mb-5">Set Your Password</h4>

        <div className="flex flex-col justify-between gap-5 mb-2">
          <div className="w-full">
            {" "}
            <label className="prod-label">Auto Generated Password</label>
            <input
              className="form-input-outline"
              value={old}
              placeholder="Autogenerated password sent to your email"
              onChange={(e) => setOld(e.target.value)}
            />
          </div>
          <div className="w-full">
            {" "}
            <label className="prod-label">New Password</label>
            <input
              className="form-input-outline"
              value={newPass}
              placeholder="Your New Password"
              onChange={(e) => setNewPass(e.target.value)}
            />
          </div>
        </div>

        <button
          onClick={(e) => setPassword(e)}
          disabled={loading}
          className="btn-primary w-full text-base font-normal px-5"
        >
          {loading ? "Please wait...." : "Set Password"}
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
