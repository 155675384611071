import React, { useState, Fragment, useEffect } from "react";

import Layout from "../../components/Layout/Layout";

import { Tab } from "@headlessui/react";

import SERVICES from "../../services";
import AllProducts from "./components/AllProducts";
import Create from "./components/Create";
import BulkActions from "./components/BulkActions";
import Categories from "./components/Categories";

function Products() {
  const [products, setProducts] = useState([]);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SERVICES.getProducts()
      .then((res) => {
        setLoading(false);
        setProducts(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [change]);

  return (
    <Layout>
      <Tab.Group>
        <div class="border-b border-gray-200 mt-2">
          <Tab.List class="flex -mb-px ">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>All Products</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Categories</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Create New Product</p>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={selected ? "tab-active" : "tab-inactive"}>
                  <p>Bulk Actions</p>
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels className="my-5">
          <Tab.Panel>
            <AllProducts
              change={change}
              setChange={setChange}
              products={products}
              loading={loading}
            />
          </Tab.Panel>
          <Tab.Panel>
            <Categories />
          </Tab.Panel>
          <Tab.Panel>
            <Create change={change} setChange={setChange} />
          </Tab.Panel>
          <Tab.Panel>
            <BulkActions change={change} setChange={setChange} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}

export default Products;
