import React, { useEffect, useState } from "react";

import capitalize from "../../../utils/Capitalize";

import SERVICES from "../../../services";

function Admins() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SERVICES.getAdmins()
      .then((res) => {
        setLoading(false);
        console.log("all admins", res.data.data);
        setAdmins(res.data.data);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error getting admins", e);
      });
  }, []);

  return (
    <div>
      {loading && (
        <div>
          <p>Loading...</p>
        </div>
      )}
      <div>
        {/* desktop */}
        <div className="hidden md:flex">
          <table class="table-auto text-left w-full text-sm font-normal">
            <thead>
              <tr className="border-b">
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Created At</th>
              </tr>
            </thead>

            <tbody>
              {admins.map((admin, index) => {
                return (
                  <tr className="border-b" key={index}>
                    <td className="py-7 px-6">
                      <span className="p-4 rounded-sm">
                        {" "}
                        {admin.lastName}, {admin.firstName}
                      </span>
                    </td>
                    <td>
                      <span className=" px-3 py-1 rounded-sm text-gray-800">
                        {admin.email}
                      </span>
                    </td>
                    <td>
                      <span className=" px-3 py-1 rounded-sm">
                        {admin.role}
                      </span>
                    </td>
                    <td>
                      <span className=" px-3 py-1 rounded-sm">
                        {admin.createdAt}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Admins;
